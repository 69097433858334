import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from '@fortawesome/free-solid-svg-icons';

function ChatbaseChatBubble() {
    // const isLoaded = useRef(false);
    // const scriptRef = useRef(null);
    // const [showChatbot, setShowChatbot] = useState(true);

    // useEffect(() => {
    //     if (showChatbot && !isLoaded.current) {
    //         const script = document.createElement('script');
    //         script.src = "https://www.chatbase.co/embed.min.js";
    //         script.defer = true;
    //         script.chatbotId = "Bx3iZvaqoTW7hL3qXc-4E";
    //         script.domain = "www.chatbase.co";
    //         document.body.appendChild(script);
    //         scriptRef.current = script;

    //         window.embeddedChatbotConfig = {
    //             chatbotId: "Bx3iZvaqoTW7hL3qXc-4E",
    //             domain: "www.chatbase.co"
    //         };
    //         isLoaded.current = true;
    //     } else {
    //         if (!showChatbot) {
    //             document.body.removeChild(scriptRef.current);
    //             scriptRef.current = null;
    //             isLoaded.current = false;
    //             const chatbotElement = document.getElementById('chatbase-bubble-button');
    //             if (chatbotElement) {
    //                 chatbotElement.parentNode.removeChild(chatbotElement);
    //             }
    //         }
    //     }
    // }, [showChatbot]);

    return (
        ""
        // <div className="closeButtonChatBot">
        //     {showChatbot && <span onClick={() => setShowChatbot(false)}><FontAwesomeIcon icon={faClose} /></span>}
        // </div>
    );
}

export default ChatbaseChatBubble;
